var vmConfirmar = new Vue({
    el: '#modal-confirmar',
    data: {
        lightbox: lity(),
        tipo    : 'simNao', // simNao ou ok
        icone   : '',
        titulo  : '',
        texto   : '',
        botao   : {
            ok  : '',
            sim : '',
            nao : ''
        },
        cb      : {
            ok  : null,
            sim : null,
            nao : null
        },
        dados   : {
            ok  : null,
            sim : null,
            nao : null
        }
    },
    methods: {
        abrir: function () {
            this.lightbox('#modal-confirmar');
        },
        callback: function ( cbTipo ) {

            this.lightbox.close();
            if( this.cb[cbTipo] ){
                return this.cb[cbTipo](this.dados[cbTipo]);
            }
        }
    }
});