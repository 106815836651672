var vmModal = new Vue({
    el: '#modal-generico',
    data: {
        lightbox    : lity(),
        classe      : 'modal-generico',
        titulo      : '',
        tipo        : '',
        parametro   : ''
    },
    methods: {
        zeraTudo: function () {

            this.classe = 'modal-generico';
            this.titulo = '';
            this.tipo = '';
            this.parametro = '';
        },
        abrir: function ( tipo, parametro ) {

            this.zeraTudo();
            Vue.nextTick(function () {
                vmModal.abreModal(tipo, parametro);
            });
        },
        abreModal: function ( tipo, parametro ) {

            this.tipo = tipo;
            this.parametro = parametro;

            this.lightbox('#modal-generico');
        },
        fechar: function () {
            this.lightbox.close();
        }
    }
});