if( $('#painel-notificacoes').length ){

    var notificacoes = new Vue({
        el: '#painel-notificacoes',
        data: {
            visivel         : false,
            numNotificacoes : 0,
            notificacoes    : { },
            redirecionando  : false,
            abrirVideo      : false
        },
        methods: {
            abreFecha: function ( event ) {

                if(event) event.stopPropagation();

                var painel = $('#painel-notificacoes');

                if( !this.visivel ){
                    painel.fadeIn();
                }else{
                    painel.fadeOut();
                }

                this.visivel = !this.visivel;
            },
            todasNotificacoes: function () {

                vmModal.abrir('notificacoes');
                this.abreFecha();
            },
            verNotificacao: function ( noti_id ) {

                var videoUrl = this.notificacoes[noti_id].video;

                if( videoUrl ){
                    this.abreFecha();
                    var modalVideo = lity();
                    modalVideo( videoUrl );
                    fechaTooltips();
                }

                this.redirecionando = this.notificacoes[noti_id].link;
                this.lida( noti_id );
            },
            lida: function ( noti_id ) {

                this.marcandoLida = noti_id;

                ajaxPOST(
                    '/notificacoes/marcar-lida',
                    { id: noti_id },
                    this.retornoLida
                );

                this.removeNotificacao(noti_id);
            },
            retornoLida: function (dados) {

                if( this.redirecionando ){

                    var link = this.redirecionando;
                    this.redirecionando = false;
                    this.abreFecha();
                    Vue.nextTick(function () {
                        window.location.replace( link );
                    });

                    return false;
                }

                if( dados.hasOwnProperty('vazio') ) return;

                // chegou aqui => veio uma nova notificacao
                this.adicionaNotificacao(dados);
            },
            removeNotificacao: function ( noti_id ) {

                this.numNotificacoes--;
                this.atualizaContador();

                if( this.notificacoes.hasOwnProperty(noti_id) ){
                    Vue.delete(this.notificacoes, noti_id );
                    fechaTooltips('.icone-visto');
                }
            },
            adicionaNotificacao: function ( notificacao ) {

                this.numNotificacoes++;
                this.atualizaContador();

                if( objSize(this.notificacoes) < 4 ){
                    Vue.set(this.notificacoes, notificacao.id, notificacao);
                }
            },
            atualizaContador: function () {

                var badge = $('.badge-notificacoes');
                var sino = $('.sino-noti');

                if( this.numNotificacoes > 0 ){
                    badge.html(this.numNotificacoes);
                    sino.addClass('fa-bell').addClass('cor-primaria').removeClass('fa-bell-o');
                }else{
                    badge.html('');
                    sino.addClass('fa-bell-o').removeClass('fa-bell').removeClass('cor-primaria');
                }
            }
        },
        created: function () {

            //pega notificacoes da view (input no navtop.blade.php)
            var notificacoes = JSON.parse($('#notificacoes-input').val());
            this.numNotificacoes = parseInt($('#num-notificacoes').val());

            if( notificacoes ){
                for(var n in notificacoes){
                    Vue.set(this.notificacoes, notificacoes[n].id, notificacoes[n]);
                }
            }
        },
        ready: function () {

            this.atualizaContador();

            if( $('#recem-logado').length && this.numNotificacoes ) this.abreFecha();

            Vue.nextTick(function () {
                inicializaTooltips('bottom right', 'top center', '.icone-visto');
            });
        }
    });

    // Fecha o painel se clicar fora dele
    $(document).click(function(event) {
        if( !$(event.target).closest('#painel-notificacoes').length && notificacoes.visivel ) {
            $('#painel-notificacoes').fadeOut();
            notificacoes.visivel = false;
        }
    });

}

