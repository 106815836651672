Vue.config.debug = true;

vmSelect2 = Vue.directive('select2', {
    twoWay: true,
    priority: 1000,

    params: ['options', 'tag'],

    bind: function() {
        Vue.nextTick(this.setup.bind(this));
    },
    setup: function () {
        var self = this;
        $(this.el)
            .select2({
                data: this.params.options,
                tags: this.params.tag ? true : false,
                placeholder: this.params.tag ? "" : "Selecione ou digite para buscar",
                language: {
                    "noResults": function () {
                        if( self.params.tag ){
                            return "Digite e pressione Enter"
                        }
                        return "Termo não encontrado";
                    }
                }
            })
            .focus(function () { $(this).select2('open'); })
            .on('change', function () {
                if( ( (typeof vmQuestoes != 'undefined') && !vmQuestoes.suspendeWatchs) ||
                    ( (typeof vmProvas != 'undefined') && !vmProvas.suspendeWatchs)
                ){
                    var selecionados = [];
                    for (var opcao in this.selectedOptions){
                        if (this.selectedOptions[opcao].value) {
                            selecionados.push(this.selectedOptions[opcao].value);
                        }
                    }
                    self.set(selecionados);
                }
            });
            /*.on('select2:open', function() {
                // pode-se fazer algo...
            });*/
    },
    update: function (value) {
        $(this.el).val(value).trigger('change');
    },
    unbind: function () {
        $(this.el).off().select2('destroy');
    }
});