// somente quando logado
if( ! $('#login-modal').length ) {

    var compTodasNotificacoes = Vue.component('todas-notificacoes', {
        props: { },
        data: function() {
            return {
                notificacoes    : {},
                total           : 0,
                exibidas        : 0,
                carregado       : false,
                temNotificacoes : false,
                aguardandoAjax  : false,
                redirecionar    : false
            }
        },
        template: '#todas-notificacoes',
        methods: {
            setNotificacoes: function (dados) {

                for( var n in dados.notificacoes ){
                    Vue.set(this.notificacoes, n, dados.notificacoes[n]);
                }
                this.total = dados.total;
                this.exibidas += dados.carregadas;
                this.carregado = true;
                this.aguardandoAjax = false;

                Vue.nextTick(inicializaTooltips);
            },
            marcar: function (noti_id) {

                this.notificacoes[noti_id].lida = !this.notificacoes[noti_id].lida;

                ajaxPOST(
                    '/notificacoes/modal/marcar',
                    { id: noti_id, lida: this.notificacoes[noti_id].lida },
                    this.retornoMarcar
                );

                this.sincronizaPainel(noti_id);
                Vue.nextTick(inicializaTooltips);
            },
            retornoMarcar: function ( dados ) {
                if( this.redirecionar ){
                    vmModal.fechar();
                    window.location.replace( this.redirecionar );
                }
                this.redirecionar = false;
            },
            sincronizaPainel: function ( noti_id ) {

                if( this.notificacoes[noti_id].lida ){
                    return notificacoes.removeNotificacao(noti_id);
                }

                notificacoes.adicionaNotificacao(this.notificacoes[noti_id]);
            },
            irParaNotificacao: function ( noti_id ) {

                var noti = this.notificacoes[noti_id];

                if( noti.video ){
                    var modalVideo = lity();
                    modalVideo( noti.video );
                    fechaTooltips();
                }

                // se tiver link => redireciona em retornoMarcar()
                this.redirecionar = noti.link;

                this.marcar(noti_id);
            },
            tituloTooltip: function ( noti_id ) {

                if( this.notificacoes[noti_id].link ){
                    return 'Ir para o local indicado';
                }

                if( this.notificacoes[noti_id].video ){
                    return 'Abrir Vídeo';
                }

                return 'Marcar como lida';
            },
            carregar: function () {

                this.aguardandoAjax = true;

                ajaxGET(
                    '/notificacoes/modal/carregar',
                    { carregadas: this.exibidas },
                    this.setNotificacoes
                )
            }
        },
        created: function () {

            this.carregar();
        }
    });

}



