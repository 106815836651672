
function mostraMsgErroSeHouver()
{
    var msgErro = $('#flash-msg-erro') ;
    if( msgErro.length ){
        flash(msgErro.val(), 'erro');
    }
}

function inicializaTooltips ( my_ , at_, elemento_ )
{
    var my = my_  ? my_ : 'bottom center';
    var at = at_ ? at_ : 'top center';
    var el = elemento_ ? elemento_ : '[tooltip]';

    $(el).qtip({
        style: 'qtip-dark',
        position: {
            my: my,
            at: at
        },
        show: { delay: 100 },
        hide: { delay: 100 }
    });
}

function fechaTooltips ( especificos )
{
    $('[tooltip]').qtip('toggle', false);

    if( especificos ){
        $(especificos).qtip('toggle', false);
    }
}

function inicializaGifPlayers()
{
    $('.gifplayer').gifplayer({ label: '&#9658;' });
}

function finalizaGifPlayers()
{
    $('.gifplayer').gifplayer('stop');
}

function scrollToTop (tempo_ms)
{
    var pagQuestoes = $("#pagina-questoes");
    var pagProvas = $("#pagina-provas");
    var pagSimulados = $("#conteudo-simulado");
    var elemento;

    if( pagQuestoes.length ){
        elemento = pagQuestoes;
    }else if ( pagProvas.length ){
        elemento = pagProvas;
    }else if ( pagSimulados.length ){
        elemento = pagSimulados;
    }else{
        elemento = $('html, body');
    }

    elemento.animate({ scrollTop: 0 }, tempo_ms);

    return false;
}


function confirma(msg, callback, dados) {
    vmConfirmar.tipo = 'simNao';
    vmConfirmar.botao.sim = 'Sim';
    vmConfirmar.botao.nao = 'Não';
    vmConfirmar.icone = 'atencao';

    dados = (typeof dados == 'undefined') ? {} : dados;

    if( typeof msg == 'object' ){
        vmConfirmar.titulo = msg.titulo;
        vmConfirmar.texto = msg.conteudo;
    }else{
        vmConfirmar.titulo = msg;
        vmConfirmar.texto = '';
    }

    if( typeof callback == 'object' ){
        vmConfirmar.cb.sim = callback.cbSim;
        vmConfirmar.dados.sim = dados.dadosSim;
        vmConfirmar.cb.nao = callback.cbNao;
        vmConfirmar.dados.nao = dados.dadosNao;
    }else{
        vmConfirmar.cb.sim = callback;
        vmConfirmar.dados.sim = dados;
        vmConfirmar.cb.nao = null;
        vmConfirmar.dados.nao = null;
    }

    vmConfirmar.abrir();
}


/* 'tipo' padrão é SUCESSO */
function ok( msg, tipo, textoBotao )
{
    vmConfirmar.tipo = 'ok';
    vmConfirmar.botao.ok = typeof textoBotao !== 'undefined' ? textoBotao : 'OK';

    if( typeof msg == 'object' ){
        vmConfirmar.titulo = msg.titulo;
        vmConfirmar.texto = msg.conteudo;
    }else{
        vmConfirmar.titulo = msg;
        vmConfirmar.texto = '';
    }

    vmConfirmar.icone = tipo ? tipo : '';

    vmConfirmar.cb.ok = null;
    vmConfirmar.abrir();
}


/* Configurações de Flash (executado apenas uma vez) */
toastr.options = {
    "positionClass": "toast-bottom-right",
    "preventDuplicates": false,
    "timeOut": "2500"
};


function erroValidacao( msg )
{
    return flash( msg, 'erro', 6000, 'top-center');
}

function flash( msg, tipo, timeOut, posicao )
{
    /* Opcional. Padrão: 2.5s */
    if( timeOut ){
        toastr.options.timeOut = timeOut;
    }

    /* Opcional. Padrão: 2.5s */
    if( posicao ){
        toastr.options.positionClass = 'toast-' + posicao;
    }

    var conteudo = msg;
    var titulo = '';
    if( typeof msg === 'object' ){
        titulo = msg.titulo;
        conteudo = msg.conteudo;
    }

    switch(tipo){
        case 'erro':
            toastr.error(conteudo, titulo);
            break;
        case 'sucesso':
            toastr.success(conteudo, titulo);
            break;
        case 'atencao':
            toastr.warning(conteudo, titulo);
            break;
        default:
            toastr.info(conteudo, titulo);
    }
}


/**
 * Formata os dados de um Form a ser submetido via Ajax,
 * deixando no mesmo formato de um Form submetido normalmente.
 *
 * @param form
 * @returns {*}
 */
function serializaForm( form )
{
    var o = {};
    var a = form.serializeArray();
    $.each(a, function() {
        var key = (this.name).replace('[]', '');
        if (o[key] !== undefined) {
            if (!o[key].push) {
                o[key] = [o[key]];
            }
            o[key].push(this.value || '');
        } else {
            o[key] = [this.value || ''];
        }
    });
    return JSON.stringify(o);
}


function objSize( obj )
{
    var i, count = 0;

    for (i in obj) {
        if (obj.hasOwnProperty(i)) {
            count++;
        }
    }
    return count;
}

function objConcat(o1, o2)
{
    for (var key in o2) {
        if (o2.hasOwnProperty(key)) {
            o1[key] = o2[key];
        }
    }
    return o1;
}

function getKeys(obj) {
    var keys = [];
    for(var key in obj){
        keys.push(key);
    }
    return keys;
}

/* IE8 abaixo parece que não suporta .trim() */
if(typeof String.prototype.trim !== 'function') {
    String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g, '');
    }
}