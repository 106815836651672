Vue.transition('abre-fecha-filtro', {
    css: false,
    beforeEnter: function (el, done) {
        // Executa quando o v-show vai pra true
        $(el).slideDown(300, function(){
            $(el).prev('.filtro-label').find('i.fa-plus-square-o')
                .removeClass('fa-plus-square-o')
                .addClass('fa-minus-square-o');
            $(el).find('.filtro-select').focus();
        });
    },
    leave: function (el, done) {
        // Executa quando o v-show vai pra false
        $(el).slideUp(300, function(){
            $(el).prev('.filtro-label').find('i.fa-minus-square-o')
                .removeClass('fa-minus-square-o')
                .addClass('fa-plus-square-o');
        });
    }
});

Vue.transition('bounce', {
    enterClass: 'bounceInLeft',
    leaveClass: 'bounceOutRight'
});

Vue.transition('slideToggle', {
    css: false,
    beforeEnter: function (el, done) {

        $(el).slideDown();

    },
    leave: function (el, done) {

        $(el).slideUp();

    }
});

Vue.transition('fade', {
    css: false,
    beforeEnter: function (el, done) {

        $(el).fadeIn();

    },
    leave: function (el, done) {

        $(el).fadeOut();

    }
});