/* Eu só preciso deste controle de Login quando o usuário esté deslogado.
* Como o div '#login-modal' só é renderizado se o usuário não estiver logado,
* utilizo isso como parâmetro. Caso contrério seria necessário fazer uma requisição
* AJAX toda vez fosse checar se há usuário logado ou não.
*/
if( $('#login-modal').length ){

    var nav = new Vue({
        el: '#link-superior-logar',
        methods: {
            open: function (which) {
                //e.preventDefault();
                if (modal.active !== null) {
                    $('#form-' + modal.active).removeClass('active');
                    $('#' + modal.active + '-form').removeClass('active');
                }

                $('#login-modal').addClass('active');
                $('#form-' + which).addClass('active');
                $('#' + which + '-form').addClass('active');

                modal.active = which;
            }
        }
    });

    var modal = new Vue({
        el: '#login-modal',
        data: {
            naPgLogin           : $('#pagina-login').length,
            active              : null,
            aguardandoLogin     : false,
            aguardandoReg       : false,
            aguardandoFace      : false,
            aguardandoEmail     : false,
            aguardandoRedefinir : false,
            login: {
                email       : null,
                senha       : null,
                erro : {
                    email : false,
                    senha : false
                }
            },
            registrar: {
                nome        : null,
                email       : null,
                nick        : null,
                senha       : null,
                senhaConf   : null,
                erro : {
                    nome        : false,
                    email       : false,
                    nick        : false,
                    senha       : false,
                    senhaConf   : false
                }
            },
            redefineSenha  : {
                token           : '',
                email           : '',
                senha       : '',
                senhaConf   : '',
                erro            : {
                    email       : false,
                    senha       : false,
                    senhaConf   : false
                }
            },
            emailDestino    : '',
            naoRecebeuEmail : false,
            redefinindoSenha  : false
        },
        methods: {
            loginFacebook: function () {

                if( this.aguardandoFace ) return;

                this.aguardandoFace = true;
                window.location.replace('/login/facebook');
            },
            entrar: function (e) {

                if( this.aguardandoLogin ) return;

                if( !this.login.email || !this.login.senha ) {
                    if( !this.login.email ){this.login.erro.email = true;}
                    if( !this.login.senha ){this.login.erro.senha = true;}
                    setTimeout(this.someInputErros, 3000);

                    return erroValidacao('Favor preencher todos os campos!');
                }

                this.aguardandoLogin = true;

                ajaxPOST(
                    '/login',
                    {
                        email   : this.login.email,
                        password: this.login.senha
                    },
                    this.retornoLogin
                );
            },
            retornoLogin: function (dados) {

                if( dados.hasOwnProperty('erros') ){
                    for(var e in dados.erros){
                        if ( e == 'senha' ) this.login.erro.senha = true;
                        if ( e == 'email' ) this.login.erro.email = true;
                        erroValidacao(dados.erros[e]);
                    }
                    setTimeout(this.someInputErros, 3000);
                    this.aguardandoLogin = false;
                    return false;
                }

                if( dados.hasOwnProperty('sucesso') ){
                    window.location.replace('/painel');
                }
            },
            registro: function (e) {

                if( !this.registrar.nome || !this.registrar.email
                    || !this.registrar.nick || !this.registrar.senha
                    || !this.registrar.senhaConf ) {

                    this.registrar.erro.nome = !this.registrar.nome;
                    this.registrar.erro.email = !this.registrar.email
                    this.registrar.erro.nick = !this.registrar.nick;
                    this.registrar.erro.senha = !this.registrar.senha;
                    this.registrar.erro.senhaConf = !this.registrar.senhaConf;

                    setTimeout(this.someInputErros, 3000);

                    return erroValidacao('Favor preencher todos os campos!');
                }

                var erros = this.validaCamposRegistro();
                if( erros.length ){
                    for(var e in erros){
                        erroValidacao(erros[e]);
                    }
                    return false;
                }

                this.postRegistrar();
            },
            validaCamposRegistro: function () {
                var msgsErro = [];
                if( (this.registrar.nome.length < 5) || (this.registrar.nome.indexOf(' ') == -1) ){
                    msgsErro.push('Preencha seu <b>nome completo</b>.');
                    this.registrar.erro.nome = true;
                }
                if( this.registrar.email.indexOf('@') == -1 ){
                    msgsErro.push('<b>E-mail inválido</b>.');
                    this.registrar.erro.email = true;
                }
                var re = /[a-zA-Z_]+[a-zA-Z0-9_.\-]+/;
                var resultado = re.exec(this.registrar.nick);
                if( !resultado || (resultado && resultado[0] != this.registrar.nick)
                    || (this.registrar.nick.length < 3) || (this.registrar.nick.length > 16) ){
                    msgsErro.push('Seu nick não pode ter caracteres especiais, espaços, nem menos de 3 dígitos.');
                    this.registrar.erro.nick = true;
                }
                if( this.registrar.senha.length < 6 ){
                    msgsErro.push('Sua senha deve ter no mínimo 6 dígitos.');
                    this.registrar.erro.senha = true;
                }else{
                    if( this.registrar.senha != this.registrar.senhaConf ){
                        msgsErro.push('Confirmação de senha diferente da senha');
                        this.registrar.erro.senhaConf = true;
                    }
                }

                if( msgsErro.length ){
                    setTimeout(this.someInputErros, 4000);
                }

                return msgsErro;
            },
            postRegistrar: function () {

                this.aguardandoReg = true;

                ajaxPOST(
                    '/registrar',
                    {
                        nome                :   this.registrar.nome,
                        email               :   this.registrar.email,
                        nick                :   this.registrar.nick,
                        senha               :   this.registrar.senha,
                        senha_confirmation  :   this.registrar.senhaConf
                    },
                    this.retornoRegistro
                )
            },
            retornoRegistro: function (dados) {

                this.aguardandoReg = false;

                if( dados.hasOwnProperty('erros') ){
                    for(var campo in dados.erros){
                        erroValidacao(dados.erros[campo], 'top-center');
                        this.registrar.erro[campo] = true;
                        if( campo == 'senha' && (dados.erros.senha.indexOf('confirmação') != -1) ){
                            this.registrar.erro.senhaConf = true;
                        }
                    }
                    return setTimeout(this.someInputErros, 4000);
                }

                if( dados.hasOwnProperty('sucesso') ){
                    this.login.email = this.registrar.email;
                    nav.open('login');
                    return ok({titulo: 'Quase Pronto! :)', conteudo: dados.sucesso});
                }

                erroValidacao('Erro desconhecido. Recarregue a página');
            },
            esqueceuSenha: function () {

                if (!this.emailDestino) {
                    return erroValidacao('Favor informar seu e-mail!');
                }

                this.aguardandoEmail = true;
                ajaxPOST('login/email-redefinir-senha', {email: this.emailDestino}, this.retornoEnvioEmail);

            },
            abreReenviarEmail: function () {
                this.naoRecebeuEmail = true;
                this.emailDestino = this.login.email;

                return nav.open('password');
            },
            reenviarEmail: function () {

                if( !this.emailDestino ){
                    return erroValidacao('Favor informar seu e-mail!');
                }

                this.aguardandoEmail = true;

                ajaxPOST('/registrar/reenviar-email', { email : this.emailDestino }, this.retornoEnvioEmail);

            },
            retornoEnvioEmail: function (dados) {

                this.aguardandoEmail = false;

                if( dados.hasOwnProperty('erro') ){
                    return erroValidacao(dados.erro);
                }

                if( dados.hasOwnProperty('sucesso') ){
                    nav.open('login');
                    return ok({ titulo:'E-mail Enviado!', conteudo: dados.sucesso }, 'sucesso');
                }

                erroValidacao('<b>Erro desconhecido</b><br>Recarregue a página.');
            },
            redefinirSenha: function () {

                if( this.aguardandoRedefinir ) return false;

                this.redefineSenha.erro.email = !this.redefineSenha.email;
                this.redefineSenha.erro.senha = !this.redefineSenha.senha;
                this.redefineSenha.erro.senhaConf = !this.redefineSenha.senhaConf;

                if( this.redefineSenha.erro.email || this.redefineSenha.erro.senha
                    || this.redefineSenha.erro.senhaConf ){
                    setTimeout(this.someInputErros, 4000);
                    return erroValidacao('Favor preencher todos os campos');
                }

                if( this.redefineSenha.senha.length < 6 ){
                    this.redefineSenha.erro.senha = true;
                    setTimeout(this.someInputErros, 4000);
                    return erroValidacao('Sua senha deve ter no mínimo 6 dígitos');
                }

                if( this.redefineSenha.senha != this.redefineSenha.senhaConf ){
                    this.redefineSenha.erro.senhaConf = true;
                    setTimeout(this.someInputErros, 4000);
                    return erroValidacao('Confirmação de Senha não é igual à senha.');
                }

                this.aguardandoRedefinir = true;
                ajaxPOST(
                    '/login/redefine-senha',
                    {
                        token               : this.redefineSenha.token,
                        email               : this.redefineSenha.email,
                        senha               : this.redefineSenha.senha,
                        senha_confirmation  : this.redefineSenha.senhaConf
                    },
                    this.retornoRedefineSenha
                );

            },
            retornoRedefineSenha: function (dados) {

                if( dados.hasOwnProperty('erros') ){
                    for(var campo in dados.erros){
                        erroValidacao(dados.erros[campo]);
                        this.redefineSenha.erro[campo] = true;
                        if( campo == 'senha' && (dados.erros.senha.indexOf('confirmação') != -1) ){
                            this.redefineSenha.erro.senhaConf = true;
                        }
                    }
                    this.aguardandoRedefinir = false;
                    return setTimeout(this.someInputErros, 4000);
                }

                if( dados.hasOwnProperty('sucesso') ){
                    return window.location.replace('/painel');
                }

                erroValidacao('Erro desconhecido. Recarregue a página');
                this.aguardandoRedefinir = false;
            },
            close: function (e) {

                // Nã página LOGIN não é possível fechar o modal
                if( this.naPgLogin ) return false;

                e.preventDefault();
                if (e.target === this.$el) {
                    $('#login-modal').removeClass('active');
                }
            },
            flip: function (which, e) {
                e.preventDefault();
                if (which !== this.active) {
                    $('#form-' + this.active).removeClass('active');
                    $('#' + this.active + '-form').removeClass('active');
                    $('#form-' + which).addClass('active');
                    $('#' + which + '-form').addClass('active');

                    this.active = which;
                }

                if( which == 'password' ){
                    if( this.login.email ){
                        this.emailDestino = this.login.email;
                    }else{
                        $('#email-destino').focus();
                    }
                }
            },
            someInputErros: function () {

                this.login.erro.email = false;
                this.login.erro.senha = false;

                this.registrar.erro.nome = false;
                this.registrar.erro.email = false;
                this.registrar.erro.nick = false;
                this.registrar.erro.senha = false;
                this.registrar.erro.senhaConf = false;

                this.redefineSenha.erro.email = false;
                this.redefineSenha.erro.senha = false;
                this.redefineSenha.erro.senhaConf = false;
            }
        }
    });


    if( modal.naPgLogin ){

        var tokenRedefinirSenha = $('#token-rs');

        if( tokenRedefinirSenha.length ){

            modal.naoRecebeuEmail = false;
            modal.redefinindoSenha = true;

            modal.redefineSenha.token = tokenRedefinirSenha.val();
            nav.open('password');
            Vue.nextTick(function () {
                $('#email-rs').focus();
            });
        }else {

            nav.open('login');

            var email = $('#email_usuario');
            if( email.length  ){
                modal.login.email = email.val();
                $('#campo-senha-login').focus();
            }else{
                $('#campo-email-login').focus();
            }

        }

    }

}
