
/**
 * Apenas um 'Sugar' pra fazer um getJSON usando
 * todos os tratamentos da minha função ajax().
 *
 * @param url
 * @param dados
 * @param cbSucesso
 * @param cbErro
 */
function ajaxGET( url, dados, cbSucesso, cbErro ) {

    ajax({
        metodo: "GET",
        url: url,
        dados: dados,
        cbSucesso: cbSucesso,
        cbErro: cbErro
    });

}


/**
 * Apenas um 'Sugar' pra fazer um post ajax usando
 * todos os tratamentos da minha função ajax().
 *
 * @param url
 * @param dados
 * @param cbSucesso
 * @param cbErro
 */
function ajaxPOST( url, dados, cbSucesso, cbErro ) {

    ajax({
        metodo: "POST",
        url: url,
        dados: dados,
        cbSucesso: cbSucesso,
        cbErro: cbErro
    });

}


/**
 * Função que encapsula uma requisição AJAX,
 * tratando devidamente as mensagens de retorno do servidor.
 *
 * @param json
 */
function ajax( json )
{
    /* Uma tentativa de diminuir os erros de csrf-token no log */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="ajax-csrf-token"]').attr('content')
        }
    });

    $.ajax({
        method: json.hasOwnProperty('metodo') ? json.metodo : "GET",
        url: json.url,
        datatype: "json",
        data: json.dados,
        success: function(dados) {

            /* Tudo ocorreu OK no servidor, mas não vai
            *  retornar nenhuma mensagem pro usuário */
            if( dados == 'OK' || dados == 'ok' ){

                if( json.hasOwnProperty('cbSucesso') && json.cbSucesso ){
                    return json.cbSucesso();
                }

                return;
            }

            if( dados == 'Unauthorized.' ){
                return flash("Erro! Recarregue a Página.", 'erro');
            }

            var retorno;
            try {
                retorno = JSON.parse(dados);
            } catch (e) {
                // algo bizarro deu errado
                return flash("Houve algum erro! Recarregue a página", 'erro');
            }

            if( retorno.hasOwnProperty('msgSucesso') ){
                return flash(retorno.msgSucesso, 'sucesso');
            }

            if( retorno.hasOwnProperty('msgErro') ){
                return flash(retorno.msgErro, 'erro');
            }

            if( json.hasOwnProperty('cbSucesso') ){
                return json.cbSucesso(retorno);
            }
        },
        error: function( jqXHR, textStatus, errorThrown ) {

            return flash('Erro cod. ' + jqXHR.status +'<br>Recarregue a página', 'erro');
        }
    });
}