if( $('#pg-define-nick').length ){

    var defineNick = new Vue({
        el: '#pg-define-nick',
        data: {
            nick            : '',
            aguardandoAjax  : false
        },
        methods: {
            salvar: function () {
                if( this.aguardandoAjax ) return false;
                this.verificaNick();
            },
            verificaNick: function () {

                if( (this.nick.length < 3) ){
                    this.inputFocus();
                    return flash('Seu nick deve ter no MÍNIMO 3 caracteres', 'erro', 5000);
                }

                if( (this.nick.length > 16) ){
                    this.inputFocus();
                    return flash('Seu nick deve ter no MÁXIMO 16 caracteres', 'erro', 5000);
                }

                var re = /[a-zA-Z_]+[a-zA-Z0-9_.\-]+/;
                var resultado = re.exec(this.nick);
                if( resultado && (resultado[0] == this.nick) ){
                    this.aguardandoAjax = true;
                    return ajaxPOST('/definenick', {nick: this.nick}, this.retornoAjax);
                }

                this.inputFocus();
                return flash('<b>Nick inválido!</b><br>Há espaços ou caracteres especiais', 'erro', 10000);
            },
            retornoAjax: function (dados) {

                this.aguardandoAjax = false;

                if( dados.hasOwnProperty('erro') ){
                    this.inputFocus();
                    return flash(dados.erro, 'erro', 10000);
                }

                if( dados.hasOwnProperty('sucesso') ){
                    return window.location.replace('/painel');
                }

                return flash('Erro desconhecido!', 'erro');
            },
            inputFocus: function () {
                $('#campo-definir-nick').focus();
            }
        },
        ready: function () {
            this.inputFocus();
        }
    });

}
