$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="ajax-csrf-token"]').attr('content')
    }
});

$(document).ready(function($){

    /* Tooltip para todos elementos com attr 'tooltip'. Conteúdo pego de 'title' */
    inicializaTooltips();

    //inicializaGifPlayers();

    /* Scroll to top script */
    $('#pagina-questoes').scroll(function(){
        if ($(this).scrollTop > 250) {
            $('#scrollup').fadeIn(300);
        } else {
            $('#scrollup').fadeOut(300);
        }
    });

    //On click scroll to top of page t = 800ms
    $('#scrollup').click(function(){
        scrollToTop(600);
    });
    /* Fim Scroll to Top */

});


